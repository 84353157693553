<script setup lang="ts">
import { ForecastReportType } from '@reports/constants'
import type { IForecastAssumption } from '@reports'

const props = withDefaults(
  defineProps<{
    count: number
    type: ForecastReportType.MONTHLY | ForecastReportType.ANNUAL
    assumptions: IForecastAssumption[]
  }>(),
  {
    count: 0,
    assumptions: () => [],
  },
)

const emit = defineEmits<{
  click: [event: Event]
}>()

const typeText = computed(() =>
  props.type === ForecastReportType.MONTHLY
    ? 'monthly'
    : props.type === ForecastReportType.ANNUAL
      ? 'annual'
      : '',
)

const MAX_TOOLTIP_ITEMS = 10

const tooltipData = computed(() => {
  if (props.assumptions && props.assumptions?.length > MAX_TOOLTIP_ITEMS + 1) {
    return [
      ...props.assumptions.slice(0, MAX_TOOLTIP_ITEMS),
      {
        leaseName: `+ ${props.assumptions.length - MAX_TOOLTIP_ITEMS} more leases`,
      },
    ]
  }

  return props.assumptions
})
</script>

<template>
  <span class="flex items-center gap-1 font-semibold">
    <template v-if="count === 1">
      <Icon name="ConsolidateCashflow" class="mr-2" />

      <span class="line-clamp-1"><slot name="single" /></span>
    </template>

    <template v-else>
      <Icon name="ConsolidateCashflow" class="mr-2" />

      <span class="line-clamp-1">Consolidate cashflow</span>

      <span class="text-sm font-medium sm:ml-2">
        Based on

        <template v-if="count > 1">
          <span class="inline-flex shrink">
            <VTooltip
              class="w-full"
              :delay="10"
              placement="bottom"
              :distance="12"
              :auto-hide="true"
            >
              <span
                class="border-primary/50 bg-primary/5 cursor-help rounded border px-2 py-1"
              >
                {{ count }}
              </span>

              <template #popper>
                <ul
                  class="space-y divide-gray-850 flex list-inside list-disc flex-col divide-y px-2 text-xs text-gray-300 *:py-1.5"
                >
                  <li
                    v-for="(assumption, index) in tooltipData"
                    :key="assumption.leaseName + index"
                    class="w-full max-w-[500px]"
                  >
                    {{ assumption.leaseName }}
                  </li>
                </ul>
              </template>
            </VTooltip>
          </span>
          Leases selected
        </template>
        <template v-else-if="count === 0">
          all leases in the portfolio
        </template>
      </span>
    </template>
  </span>
</template>
